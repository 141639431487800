import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms'
import { stateOptions } from '@services/billing/countries-and-states'
import { BillingService } from '../../../services/billing/billing.service'

@Component({
  selector: 'app-billing-address-overlay',
  templateUrl: './billing-address-overlay.component.html',
  styleUrls: ['./billing-address-overlay.component.scss'],
})
export class BillingAddressOverlayComponent implements OnInit {
  @Output() closeClick = new EventEmitter()

  @Output() submitClick = new EventEmitter()

  constructor(public billing: BillingService) {}

  ngOnInit(): void {
    // Fire this off again to clear any values which had been entered but not saved.

    this.billing.createBillingAddressForm()
    this.billing.billingAddressForm.get('country')?.valueChanges.subscribe((country) => {
      this.updateStateOptions(country)
      this.updateFieldDisabled()
    })
    const initCountry = this.billing.billingAddressForm.get('country')?.value
    this.updateStateOptions(initCountry)
    this.updateFieldDisabled()
  }

  updateStateOptions(countryCode: string) {
    const stateField = this.billing.billingFormCompanyFields.find((field) => field.key === 'state')
    if (stateField) {
      stateField.selectValues = stateOptions[countryCode] || []
    }
  }

  updateFieldDisabled() {
    const stateControl = this.billing.billingAddressForm.get('state')
    const countryValue = this.billing.billingAddressForm.get('country')?.value
    const vatControl = this.billing.billingAddressForm.get('vatNumber')
    if (!stateOptions.hasOwnProperty(countryValue)) {
      stateControl?.disable()
    } else {
      stateControl?.enable()
    }
    // Disable VAT number field for US
    if (countryValue === 'US') {
      vatControl?.disable()
      vatControl?.setValue('')
    } else {
      vatControl?.enable()
    }
  }

  toggleCustomerNumber(): void {
    if (this.billing.hideCustomerNumberFieldControl.value) {
      this.billing.billingAddressForm.removeControl('pxcCustomerId')
      this.billing.billingFormCompanyFields.pop()
    } else {
      this.billing.billingFormCompanyFields.push({
        type: 'text',
        placeholder: 'Phoenix Contact Customer ID',
        key: 'pxcCustomerId',
        inputId: 'billing-pxc-customer-id',
        control: new UntypedFormControl(this.billing.billingAccount?.pxcCustomerId || '', [Validators.required, Validators.minLength(1)]),
        maxLength: 80,
      })
      this.billing.billingAddressForm.addControl(
        'pxcCustomerId',
        this.billing.billingFormCompanyFields[this.billing.billingFormCompanyFields.length - 1].control
      )
    }
  }
}
