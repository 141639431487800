@if (navigation.organisationMenuToggled$ | async) {
  <div class="profile-menu_container">
    <div class="profile-menu_title">Active organization & settings</div>
    <div class="profile-menu_info">
      <div class="profile-menu_icon-text profile-menu_icon-text--black">
        <app-icon class="profile-menu_icon-text__icon" name="accountGroupIcon" color="var(--jet-black)"></app-icon>
        <div class="profile-menu_icon-text__text">
          {{ proficloud.currentOrganisation?.organizationDetails?.organizationName | truncate: [30] }}
        </div>
      </div>
      <div
        class="profile-menu_icon-text profile-menu_icon-text--green profile-menu_icon-text__button"
        (click)="openSettings('Organizations'); navigation.hideOrgMenu()">
        <app-icon class="profile-menu_icon-text__icon" name="gear" color="var(--primary)"></app-icon>
        <div class="profile-menu_icon-text__text">Settings</div>
      </div>
    </div>
    <div class="profile-menu_title">Available organizations</div>
    <pc-input-search [placeholder]="'Search for organizations'" variant="fit-width" (valueChanged)="searchExecuted($event)"></pc-input-search>
    @if (filteredOrganizations?.length < 1) {
      <div class="profile-menu_no_org">No organizations found</div>
    }
    <div>
      @if (filteredOrganizations?.length > 0) {
        <pc-scroll [horizontal]="false" [vertical]="true" [forceHeight]="200">
          <div class="profile-menu_organisations">
            @for (org of filteredOrganizations; track org) {
              <div
                (click)="iam.switchOrganisation(org); navigation.hideOrgMenu()"
                class="profile-menu_icon-text profile-menu_icon-text--green profile-menu_icon-text__button">
                <app-icon class="profile-menu_icon-text__icon" name="accountGroupIcon" color="var(--primary)"></app-icon>
                <div title="{{ org.organizationName }}" class="profile-menu_icon-text__text">
                  {{ org.organizationName | truncate: [25] }}
                </div>
              </div>
            }
          </div>
        </pc-scroll>
      }
    </div>
    <div
      class="profile-menu_icon-text profile-menu_icon-text--green profile-menu_icon-text__button"
      (click)="navigation.showCreateOrganisation(); navigation.hideOrgMenu()">
      <app-icon class="profile-menu_icon-text__icon" name="plus" color="var(--primary)"></app-icon>
      <div class="profile-menu_icon-text__text">New organization</div>
    </div>
  </div>
}
@if (navigation.profileMenuToggled$ | async) {
  <div class="profile-menu_container__2">
    <div class="profile-menu_title">That's you</div>
    <div class="profile-menu_info">
      <div
        class="profile-menu_icon-text profile-menu_icon-text--green profile-menu_icon-text__button"
        (click)="openSettings('Account Settings'); navigation.hideProfileMenu()">
        <app-icon class="profile-menu_icon-text__icon" name="person" color="var(--persian-green)"></app-icon>
        <div class="profile-menu_icon-text__text">Account Settings</div>
      </div>
    </div>
    <div class="profile-menu_title">
      Have a great day, {{ proficloud.keycloakData.userDetails?.data.firstName }} {{ proficloud.keycloakData.userDetails?.data.lastName }}!
    </div>
    <div class="profile-menu_divider"></div>
    <div
      class="profile-menu_icon-text profile-menu_icon-text--green profile-menu_icon-text__button"
      (click)="proficloud.logoutClick$.next(undefined); navigation.hideProfileMenu()">
      <app-icon class="profile-menu_icon-text__icon" name="logout" color="var(--primary)"></app-icon>
      <div class="profile-menu_icon-text__text">Logout</div>
    </div>
  </div>
}
