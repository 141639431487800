import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ChargeRepaySubscription } from '@chargeRepay/charge-repay.interfaces'
import { TranslateService } from '@ngx-translate/core'
import { AuthorisationService } from '@services/authorisation.service'
import { BillingService } from '@services/billing/billing.service'
import { HttpBaseService } from '@services/http-base.service'
import {
  BookingPackage,
  DeviceInfo,
  IaSubscription,
  ImpulsePackagesResponseItem,
  ServiceIds,
  SubscriptionResponse,
  TsdPackagesResponseItem,
  TsdSubscription,
} from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { Subscription } from 'rxjs'
import { DeviceBillingService, DeviceBillingSubscription } from '../../modules/device-management/services/device-billing.service'
import { DeviceService } from '../../modules/device-management/services/device.service'
import { BillingStore } from '../../stores/billing.store'
@Component({
  selector: 'app-subscription-information',
  templateUrl: './subscription-information.component.html',
  styleUrls: ['./subscription-information.component.scss'],
})
export class SubscriptionInformationComponent implements OnInit, OnDestroy {
  @Input({ required: true }) subscription: IaSubscription | DeviceBillingSubscription | TsdSubscription | ChargeRepaySubscription | SubscriptionResponse // EMMA
  @Input({ required: true }) subscriptionType: ServiceIds
  usedSlots = 0
  totalSlots = 0

  package?: BookingPackage | TsdPackagesResponseItem | ImpulsePackagesResponseItem
  description: string = ''
  referenceCode: string = ''
  internalOrderId: string = ''
  devices: DeviceInfo[] = []
  endpointIds: string[] = []
  serviceName: string
  isFreeTSD = false
  showCancelOption = false
  slotsText: string = 'USR_SETTINGS.DEVICE_SLOTS'
  infoboxText: string = 'USR_SETTINGS.DEVICES_USED_INFOBOX'
  endDate?: string

  private tsdSubscriptionsListed: Subscription

  constructor(
    public authorisation: AuthorisationService,
    public billing: BillingService,
    private billingStore: BillingStore,
    private deviceBilling: DeviceBillingService,
    public deviceService: DeviceService,
    public httpBase: HttpBaseService,
    public proficloud: ProficloudService,
    public translate: TranslateService,
    public ui: UiService
  ) {}

  ngOnInit() {
    this.computeSubscriptionInformation()

    this.tsdSubscriptionsListed = this.billingStore.allSubscriptions$.subscribe(() => {
      this.computeSubscriptionInformation()
    })
  }

  ngOnDestroy(): void {
    this.tsdSubscriptionsListed?.unsubscribe()
  }

  private computeSubscriptionInformation() {
    this.totalSlots = this.subscription.quantity
    this.referenceCode = this.subscription.referenceCode
    this.endDate = this.subscription.endDate
    switch (this.subscriptionType) {
      case 'ems':
        this.package = this.billing.getPackageById(this.subscription.bookedPackageId)
        //Description
        this.description = (this.package as BookingPackage)?.description
        this.serviceName = 'USR_SETTINGS.EMMA_SERVICE'
        break
      case 'ia':
        this.endpointIds = (this.subscription as IaSubscription).devicesUsed
        this.usedSlots = this.endpointIds.length
        this.package = this.billing.getPackageById(this.subscription.bookedPackageId)
        //Description
        this.description = (this.package as BookingPackage)?.description
        this.serviceName = 'USR_SETTINGS.IA_SERVICE'
        break
      case 'dmsAddonBasic':
        this.endpointIds = (this.subscription as DeviceBillingSubscription).endpointIds
        this.usedSlots = this.endpointIds.length
        this.package = this.deviceBilling.bookingPackages.find((pack) => {
          return pack.id == this.subscription.bookedPackageId
        })
        this.description = (this.package as BookingPackage)?.description
        this.serviceName = 'USR_SETTINGS.DMS_BASIC_ADD_ON'
        // Hack(JanLippemeier) we have to wait for Billing and Device Billing to complete however sometimes they are already finished
        if (this.billing.dmsBaSubscriptions.length == this.deviceBilling.subscriptions.length) {
          this.endDate = this.billing.dmsBaSubscriptions.find((sub) => sub.id == this.subscription.id)?.endDate
        }
        break
      case 'tsd':
        this.package = this.billing.getPackageById(this.subscription.bookedPackageId) as TsdPackagesResponseItem
        this.description = (this.package as TsdPackagesResponseItem).description
        this.isFreeTSD = this.package.name === 'TSD Free'
        if (this.isFreeTSD) {
          this.translate.get('USR_SETTINGS.FREE_SUBSCRIPTION').subscribe((translation) => {
            this.referenceCode = translation
          })
          this.translate.get('USR_SETTINGS.FREE_TSD_DESCRIPTION').subscribe((translation) => {
            this.description = translation
          })
        }
        this.usedSlots = this.billing.tsdTotalMetricsUsed
        this.totalSlots = this.billing.getTsdPackageById(this.subscription.bookedPackageId)?.maxNumberOfMetrics || 0
        this.serviceName = 'USR_SETTINGS.TSDSERVICE'
        this.slotsText = 'USR_SETTINGS.METRIC_SLOTS'
        this.infoboxText = 'USR_SETTINGS.METRICS_USED_INFOBOX'
        break
      case 'chargeRepay':
        this.package = this.billing.chargeRepayPackage
        this.description = this.package.description
        this.serviceName = this.billing.chargeRepayPackage.name
        this.usedSlots = this.totalSlots - (this.subscription as ChargeRepaySubscription).seatsFreeCount
        this.slotsText = 'USR_SETTINGS.CHARGE_REPAY_SLOTS'
        this.infoboxText = 'USR_SETTINGS.SEATS_USED_INFOBOX'
    }
    this.devices = this.deviceService.endpointIDsToDevices(this.endpointIds).filter((v) => v !== undefined)
  }
  assignDevices() {
    switch (this.subscriptionType) {
      // Note: We no longer care about EMMA as it cannot have devices assigned to it
      case 'ia':
        this.billing.showAssignDevicesModal$.next({
          subscription: this.subscription as SubscriptionResponse,
          candidateDevices: this.billing.iaCapableDevices,
          service: 'impulse',
          devicesUsed: this.endpointIds,
        })
        break
      case 'dmsAddonBasic':
        this.billing.showAssignDevicesModal$.next({
          subscription: this.subscription as SubscriptionResponse,
          candidateDevices: this.deviceService.devices || [],
          service: 'dmsAddonBasic',
          devicesUsed: this.endpointIds,
        })
        break
    }
  }
}
